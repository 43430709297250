import { cardsQuery } from '@/state/cards/cards.query';
import { cardsService } from '@/state/cards/cards.service';
import { CardOwner } from '@/core/models';
import { coreBus } from '@/core/core-bus';
import { CardDealerBaseController } from '@/core/card-dealer.base.controller';

export class DealerController extends CardDealerBaseController {
    constructor() {
        super(30, 52);
    }

    protected dealCard(i: number) {
        const card = cardsQuery.getTopByOwner(CardOwner.none);
        if (!card) {
            throw new Error('no card in stock');
        }

        // tableau 1
        if (i <= 7) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 1,
                order: i,
                dragEnabled: true,
            });
        }

        // tableau 2
        if (i >= 8 && i <= 14) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 2,
                order: i - 7,
                dragEnabled: true,
            });
        }

        // tableau 3
        if (i >= 15 && i <= 21) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 3,
                order: i - 14,
                dragEnabled: true,
            });
        }

        // tableau 4
        if (i >= 22 && i <= 28) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 4,
                order: i - 21,
                dragEnabled: true,
            });
        }

        // tableau 5
        if (i >= 29 && i <= 34) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 5,
                order: i - 28,
                dragEnabled: true,
            });
        }

        // tableau 6
        if (i >= 35 && i <= 40) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 6,
                order: i - 34,
                dragEnabled: true,
            });
        }

        // tableau 7
        if (i >= 41 && i <= 46) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 7,
                order: i - 40,
                dragEnabled: true,
            });
        }

        // tableau 8
        if (i >= 47 && i <= 52) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 8,
                order: i - 46,
                dragEnabled: true,
            });
        }

        coreBus.cardMoveCmd$.next({
            duration: 0,
            cardId: card.id,
        });
    }
}
