import { bus } from '@/games/freecell/bus';
import { Card, CardOwner } from '@/core/models';
import { judge } from '@/games/freecell/judge';
import { moveHistory } from '@/core/move-history';
import { cardsService } from '@/state/cards/cards.service';
import { cardsQuery } from '@/state/cards/cards.query';
import { gameService } from '@/state/game/game.service';
import { coreBus } from '@/core/core-bus';
import { CardMoveBaseController } from '@/core/card-move-base.controller';

export class MoveController extends CardMoveBaseController {
    constructor() {
        super({
            enableTableauAutoAdjust: true,
            meldCard: {
                validate: (cmd) => judge.canPutCardOnTopOf(cmd.card, cmd.destCard),
            },
            moveCardToFoundation: {
                validate: (cmd) => judge.canPutInFoundation(cmd.card, cmd.foundationIndex),
            },
            moveCardToEmptyTableauCmd: {
                validate: (cmd) =>
                    judge.canMoveToSpecificTableauEmptyFrame(cmd.card, cmd.tableauIndex),
            },
        });
        this.subscribeTo(bus.moveCardToFreeCellCmd$, (ev) => {
            this.moveCardToFreeCell(ev.card, ev.freeCellIndex);
            coreBus.gameMoveCompletedEvent$.next();
        });
        this.subscribeTo(bus.moveCardToOriginCmd$, (ev) => {
            this.moveCardToOrigin(ev.card);
            coreBus.gameMoveCompletedEvent$.next();
        });
    }

    private moveCardToFreeCell(card: Card, freeCellIndex: number) {
        if (!judge.canPutCardInSpecificFreeCell(card, freeCellIndex) || !this.canMakeMove()) {
            this.moveCardToOrigin(card);
            return;
        }
        moveHistory.startMove();
        cardsService.update(card.id, {
            owner: CardOwner.freeCell,
            ownerIndex: freeCellIndex,
            order: 1,
            dragEnabled: true,
        });
        moveHistory.addState(card);
        cardsService.updateTableauCount();
        coreBus.cardMoveCmd$.next({
            cardId: card.id,
            duration: 300,
        });

        gameService.increaseMoveCounter();
        moveHistory.endMove();

        this.moveTableauCards([card.id]);
    }

    private moveCardToOrigin(card: Card) {
        coreBus.cardMoveCmd$.next({
            cardId: card.id,
            duration: 300,
        });
        // move cards below as well
        let nextOrder = card.order + 1;
        while (nextOrder > 0) {
            const belowCard = cardsQuery.getCardByOwnerAndIndexAndOrder(
                card.owner,
                card.ownerIndex,
                nextOrder
            );
            if (belowCard) {
                coreBus.cardMoveCmd$.next({
                    cardId: belowCard.id,
                    duration: 300,
                });
                nextOrder++;
            } else {
                nextOrder = 0;
            }
        }
    }
}
