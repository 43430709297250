import { Subject } from 'rxjs';
import { Card } from '@/core/models';

class Bus {
    meldCardCmd$ = new Subject<MeldCardCmd>();

    moveCardToFoundationCmd$ = new Subject<MoveCardToFoundationCmd>();

    moveCardToFreeCellCmd$ = new Subject<MoveCardToFreeCellCmd>();

    moveCardToOriginCmd$ = new Subject<MoveCardToOriginCmd>();

    moveCardToEmptyTableauCmd$ = new Subject<MoveCardToEmptyTableauCmd>();

    openGameMenu$ = new Subject();
}

export const bus = new Bus();

export type MeldCardCmd = {
    card: Card;
    destCard: Card;
};

export type MoveCardToFoundationCmd = {
    card: Card;
    foundationIndex: number;
};

export type MoveCardToFreeCellCmd = {
    card: Card;
    freeCellIndex: number;
};

export type MoveCardToOriginCmd = {
    card: Card;
};

export type MoveCardToEmptyTableauCmd = {
    card: Card;
    tableauIndex: number;
};
