import { bus } from '@/games/freecell/bus';
import { display } from '@/games/freecell/display';
import { judge } from '@/games/freecell/judge';
import { CardDragBaseController } from '@/core/card-drag.base.controller';

export class DragController extends CardDragBaseController {
    constructor() {
        super({
            tableauCardIntersect: {
                canPutCardOnTopOf: (card, dest) => judge.canPutCardOnTopOf(card, dest),
            },
            foundationIntersect: {
                frames: () => [
                    { position: display.foundation1Position, index: 1 },
                    { position: display.foundation2Position, index: 2 },
                    { position: display.foundation3Position, index: 3 },
                    { position: display.foundation4Position, index: 4 },
                ],
                validate: (card, index) => judge.canPutInFoundation(card, index),
            },
            tableauIntersect: {
                frames: () => [
                    { position: display.tableau1Position, index: 1 },
                    { position: display.tableau2Position, index: 2 },
                    { position: display.tableau3Position, index: 3 },
                    { position: display.tableau4Position, index: 4 },
                    { position: display.tableau5Position, index: 5 },
                    { position: display.tableau6Position, index: 6 },
                    { position: display.tableau7Position, index: 7 },
                    { position: display.tableau8Position, index: 8 },
                ],
                validate: (card, index) => judge.canMoveToSpecificTableauEmptyFrame(card, index),
            },
            otherIntersect: [
                {
                    frames: () => [
                        { position: display.free1Position, index: 1 },
                        { position: display.free2Position, index: 2 },
                        { position: display.free3Position, index: 3 },
                        { position: display.free4Position, index: 4 },
                    ],
                    validate: (card, index) => judge.canPutCardInSpecificFreeCell(card, index),
                    trigger: (card, index) => {
                        bus.moveCardToFreeCellCmd$.next({
                            card,
                            freeCellIndex: index,
                        });
                    },
                },
            ],
        });
    }
}
