/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { gameQuery } from '@/state/game/game.query';
import { Card, CardOwner, GameStatus, Suit } from '@/core/models';
import { cardsService } from '@/state/cards/cards.service';
import { cardsQuery } from '@/state/cards/cards.query';
import { judge } from '@/games/freecell/judge';
import { keyBy, range } from 'lodash';
import { moveHistory } from '@/core/move-history';
import { BaseGameController } from '@/core/base-game.controller';
import { coreBus } from '@/core/core-bus';

export class GameController extends BaseGameController {
    protected initGame(): void {
        cardsService.resetAllCards();
        const updates = cardsQuery.getAll().map((c) => ({
            ...c,
            isFaceUp: true,
        }));
        cardsService.upsertMany(updates);
        moveHistory.reset();
    }

    protected isGameCompleted(): boolean {
        return cardsQuery.getAllByOwner(CardOwner.foundation).length == 52;
    }

    constructor() {
        super();
        this.subscription.add(
            gameQuery.gameStatus$.subscribe((status) => {
                if (status == GameStatus.dealCompleted) {
                    this.enableDisableDragForAllCards();
                    this.updateCardsFadeStatus();
                    if (process.env.NODE_ENV === 'development') {
                        setTimeout(() => {
                            this.setCustomBoard();
                            this.enableDisableDragForAllCards();
                            this.updateCardsFadeStatus();
                        }, 2000);
                    }
                }
            })
        );
        this.subscription.add(
            coreBus.gameMoveCompletedEvent$.subscribe(() => {
                this.enableDisableDragForAllCards();
                this.updateCardsFadeStatus();
            })
        );
        this.initStage();
    }

    private initStage() {
        const suits = [Suit.Diamond, Suit.Spade, Suit.Club, Suit.Heart];
        const cards = suits.flatMap((s) => {
            return range(1, 14).map(
                (r) =>
                    ({
                        id: `${r}${s}`,
                        suit: s,
                        rank: r,
                        order: 0,
                        dragEnabled: false,
                        isFaceUp: true,
                        owner: CardOwner.none,
                    } as Card)
            );
        });
        cardsService.init(cards);
    }

    private enableDisableDragForAllCards() {
        const updates: Card[] = [];

        // enable all free cell cards
        cardsQuery
            .getAllByOwner(CardOwner.freeCell)
            .filter((c) => !c.dragEnabled)
            .forEach((c) => {
                updates.push({
                    ...c,
                    dragEnabled: true,
                });
            });

        // func to process tableau
        const processTableau = (tabIndex: number) => {
            const cards = cardsQuery.getOrderedByOwnerAndIndex(CardOwner.tableau, tabIndex);
            if (cards.length == 0) {
                return;
            }

            // top card always enabled
            updates.push({
                ...cards[cards.length - 1],
                dragEnabled: true,
            });

            let isSequenceDone = false;
            for (let i = cards.length - 2; i >= 0; i--) {
                const c = cards[i];
                const cp = cards[i + 1];
                const isSequence = c.rank == cp.rank + 1;
                const cColor = c.suit == Suit.Heart || c.suit == Suit.Diamond ? 'r' : 'b';
                const cpColor = cp.suit == Suit.Heart || cp.suit == Suit.Diamond ? 'r' : 'b';
                const isAltColor = cColor != cpColor;
                if (isSequence && isAltColor && !isSequenceDone) {
                    updates.push({
                        ...c,
                        dragEnabled: true,
                    });
                } else {
                    isSequenceDone = true;
                    updates.push({
                        ...c,
                        dragEnabled: false,
                    });
                }
            }
        };

        processTableau(1);
        processTableau(2);
        processTableau(3);
        processTableau(4);
        processTableau(5);
        processTableau(6);
        processTableau(7);
        processTableau(8);

        cardsService.upsertMany(updates);
    }

    private updateCardsFadeStatus() {
        const maxSize = judge.calcMaxMoveableSequenceSize();
        const topTabs = keyBy(cardsQuery.getTopTableauCards(), (c) => c.owner);

        const updates = cardsQuery.getAll().map((card) => {
            if (card.owner != CardOwner.tableau) {
                return { ...card, isFaded: false };
            }

            // get top card for tableau
            const top = topTabs[card.owner];

            // top cord is never faced
            if (top.id == card.id) {
                return { ...card, isFaded: false };
            }

            return {
                ...card,
                isFaded: !(card.order + maxSize > top.order && card.dragEnabled),
            };
        });

        cardsService.upsertMany(updates);
    }

    private setCustomBoard() {
        const f1 = ['1H', '2H', '3H', '4H', '5H', '6H', '7H'].map((id, i) => {
            const card = cardsQuery.getEntity(id);
            return {
                ...card!,
                dragEnabled: true,
                owner: CardOwner.foundation,
                ownerIndex: 1,
                order: i + 1,
            };
        });
        const f2 = ['1S', '2S', '3S', '4S', '5S'].map((id, i) => {
            const card = cardsQuery.getEntity(id);
            return {
                ...card!,
                dragEnabled: true,
                owner: CardOwner.foundation,
                ownerIndex: 2,
                order: i + 1,
            };
        });
        const f3 = ['1D', '2D', '3D', '4D', '5D'].map((id, i) => {
            const card = cardsQuery.getEntity(id);
            return {
                ...card!,
                dragEnabled: true,
                owner: CardOwner.foundation,
                ownerIndex: 3,
                order: i + 1,
            };
        });
        const f4 = ['1C', '2C', '3C', '4C', '5C', '6C', '7C', '8C', '9C', '10C'].map((id, i) => {
            const card = cardsQuery.getEntity(id);
            return {
                ...card!,
                dragEnabled: true,
                owner: CardOwner.foundation,
                ownerIndex: 4,
                order: i + 1,
            };
        });
        const t1 = ['13C', '12H', '11S', '10D', '9S', '8D', '7S', '6D'].map((id, i) => {
            const card = cardsQuery.getEntity(id);
            return {
                ...card!,
                dragEnabled: true,
                owner: CardOwner.tableau,
                ownerIndex: 1,
                order: i + 1,
            };
        });
        const t2 = ['11C'].map((id, i) => {
            const card = cardsQuery.getEntity(id);
            return {
                ...card!,
                dragEnabled: true,
                owner: CardOwner.tableau,
                ownerIndex: 2,
                order: i + 1,
            };
        });
        const t3 = ['13H'].map((id, i) => {
            const card = cardsQuery.getEntity(id);
            return {
                ...card!,
                dragEnabled: true,
                owner: CardOwner.tableau,
                ownerIndex: 3,
                order: i + 1,
            };
        });
        const t4 = ['11D'].map((id, i) => {
            const card = cardsQuery.getEntity(id);
            return {
                ...card!,
                dragEnabled: true,
                owner: CardOwner.tableau,
                ownerIndex: 4,
                order: i + 1,
            };
        });
        const t5 = ['13D', '12C', '11H'].map((id, i) => {
            const card = cardsQuery.getEntity(id);
            return {
                ...card!,
                dragEnabled: true,
                owner: CardOwner.tableau,
                ownerIndex: 5,
                order: i + 1,
            };
        });
        const t6 = [].map((id, i) => {
            const card = cardsQuery.getEntity(id);
            return {
                ...card!,
                dragEnabled: true,
                owner: CardOwner.tableau,
                ownerIndex: 6,
                order: i + 1,
            };
        });
        const t7 = ['9H', '12D', '10H', '8H', '12S', '9D', '8S', '7D', '6S'].map((id, i) => {
            const card = cardsQuery.getEntity(id);
            return {
                ...card!,
                dragEnabled: true,
                owner: CardOwner.tableau,
                ownerIndex: 7,
                order: i + 1,
            };
        });
        const free1 = [].map((id, i) => {
            const card = cardsQuery.getEntity(id);
            return {
                ...card!,
                dragEnabled: true,
                owner: CardOwner.freeCell,
                ownerIndex: 1,
                order: i + 1,
            };
        });
        const free2 = ['13S'].map((id, i) => {
            const card = cardsQuery.getEntity(id);
            return {
                ...card!,
                dragEnabled: true,
                owner: CardOwner.freeCell,
                ownerIndex: 2,
                order: i + 1,
            };
        });
        const free3 = ['10S'].map((id, i) => {
            const card = cardsQuery.getEntity(id);
            return {
                ...card!,
                dragEnabled: true,
                owner: CardOwner.freeCell,
                ownerIndex: 4,
                order: i + 1,
            };
        });

        const all = f1
            .concat(f2)
            .concat(f3)
            .concat(f4)
            .concat(t1)
            .concat(t2)
            .concat(t3)
            .concat(t4)
            .concat(t5)
            .concat(t6)
            .concat(t7)
            .concat(free1)
            .concat(free2)
            .concat(free3);
        cardsService.upsertMany(all);
        all.forEach((c) => {
            coreBus.cardMoveCmd$.next({
                cardId: c.id,
                duration: 0,
            });
        });
    }
}
