import { bus } from '@/games/freecell/bus';
import { Card, CardOwner, Hint } from '@/core/models';
import { judge } from '@/games/freecell/judge';
import { cardsQuery } from '@/state/cards/cards.query';
import { orderBy } from 'lodash';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';
import { coreBus } from '@/core/core-bus';
import { CardAutoMoveBaseController } from '@/core/card-auto-move.base.controller';
import { coreUtil } from '@/core/core-util';
import { cardSound } from '@/core/sound';

export class AutoMoveController extends CardAutoMoveBaseController {
    protected doBestMove(card: Card) {
        if (card.owner == CardOwner.foundation) {
            return;
        }

        // try to move card to foundation
        const foundationIndex = coreUtil.getFoundation4IndexForSuit(card.suit);
        if (judge.canPutInFoundation(card, foundationIndex)) {
            coreBus.moveCardToFoundationCmd$.next({
                card,
                foundationIndex,
            });
            return;
        }

        // try to meld card
        const tableauTops = cardsQuery.getTopTableauCards();
        for (let i = 0; i < tableauTops.length; i++) {
            const top = tableauTops[i];
            if (judge.canPutCardOnTopOf(card, top)) {
                coreBus.meldCardCmd$.next({
                    card,
                    destCard: top,
                });
                return;
            }
        }

        // try to move sequence to empty tableau
        const topCards = cardsQuery.getTopTableauCards();
        const allTabs = [1, 2, 3, 4, 5, 6, 7, 8];
        const emptyTabs = allTabs.filter(
            (t) => !topCards.find((c) => c.owner == CardOwner.tableau && c.ownerIndex == t)
        );
        for (let i = 0; i < emptyTabs.length; i++) {
            if (judge.canMoveToSpecificTableauEmptyFrame(card, emptyTabs[i])) {
                coreBus.moveCardToEmptyTableauCmd$.next({
                    card,
                    tableauIndex: emptyTabs[i],
                });
                return;
            }
        }

        // try to move card to free cell
        for (let i = 1; i <= 4; i++) {
            if (judge.canPutCardInSpecificFreeCell(card, i)) {
                bus.moveCardToFreeCellCmd$.next({
                    card,
                    freeCellIndex: i,
                });
                return;
            }
        }

        coreBus.shakeCardCmd$.next({
            card,
        });
    }

    protected isAutoFinishPossible() {
        // if all cards in tableau ordered by rank, then we can auto finish
        for (let t = 1; t <= 8; t++) {
            const cards = cardsQuery.getOrderedByOwnerAndIndex(CardOwner.tableau, t);
            if (cards.length <= 1) {
                continue;
            }
            for (let i = 1; i < cards.length; i++) {
                if (cards[i - 1].rank <= cards[i].rank) {
                    return false;
                }
            }
        }

        return true;
    }

    protected doAutoFinish(done: () => void) {
        console.log('---do auto finish');
        const tabCards = cardsQuery.getAllByOwner(CardOwner.tableau);
        const freeCards = cardsQuery.getAllByOwner(CardOwner.freeCell);
        const cards = tabCards.concat(freeCards);
        const ordered = orderBy(cards, (c) => c.rank);

        const sub = interval(100)
            .pipe(take(ordered.length))
            .subscribe({
                next: (i) => {
                    const card = ordered[i];
                    const foundIndex = coreUtil.getFoundation4IndexForSuit(card.suit);
                    coreBus.moveCardToFoundationCmd$.next({
                        card,
                        foundationIndex: foundIndex,
                    });
                },
                complete: () => {
                    done();
                },
            });

        this.subscription.add(sub);
    }

    protected generateHints() {
        const tabTops = cardsQuery.getTopTableauCards();
        const tabCards = cardsQuery.getAllByOwner(CardOwner.tableau).filter((c) => c.dragEnabled);

        // hints for tableau
        const tabHints = tabCards.flatMap((c) => {
            return tabTops
                .filter((tc) => tc.ownerIndex != c.ownerIndex && judge.canPutCardOnTopOf(c, tc))
                .map(
                    (tc) =>
                        ({
                            card1Id: c.id,
                            card2Id: tc.id,
                            priority: 1,
                        } as Hint)
                );
        });

        // hints for foundation
        const foundHints = tabTops
            .filter((c) => judge.canPutInFoundation(c, coreUtil.getFoundation4IndexForSuit(c.suit)))
            .map((c) => {
                const foundIndex = coreUtil.getFoundation4IndexForSuit(c.suit);
                const foundCard = cardsQuery.getTopByOwnerAndIndex(
                    CardOwner.foundation,
                    foundIndex
                );
                return {
                    card1Id: c.id,
                    card2Id: foundCard ? foundCard.id : null,
                    foundationIndex: foundCard ? null : foundIndex,
                    priority: 0,
                } as Hint;
            });

        return tabHints.concat(foundHints);
    }
}
