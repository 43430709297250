import { Card, CardOwner, Suit } from '@/core/models';
import { cardsQuery } from '@/state/cards/cards.query';
import { coreUtil } from '@/core/core-util';

export class Judge {
    canPutCardOnTopOf(card: Card, dest: Card) {
        if (dest.owner == CardOwner.tableau) {
            if (!this.canMoveCardSequence(card)) {
                return false;
            }
            if (card.rank != dest.rank - 1) {
                return false;
            }
            if (
                (dest.suit == Suit.Heart || dest.suit == Suit.Diamond) &&
                (card.suit == Suit.Club || card.suit == Suit.Spade)
            ) {
                return true;
            }
            return (
                (dest.suit == Suit.Club || dest.suit == Suit.Spade) &&
                (card.suit == Suit.Heart || card.suit == Suit.Diamond)
            );
        }
        return false;
    }

    canPutCardInSpecificFreeCell(card: Card, freeCellIndex: number) {
        // first check if free cell is empty
        const c = cardsQuery.getTopByOwnerAndIndex(CardOwner.freeCell, freeCellIndex);
        if (c) {
            return false;
        }

        // if card is come from tableau check that it's in the top of the tableau
        if (card.owner == CardOwner.tableau || card.owner == CardOwner.freeCell) {
            const top = cardsQuery.getTopByOwnerAndIndex(card.owner, card.ownerIndex);
            if (top && top.id == card.id) {
                return true;
            }
        }
        return false;
    }

    canPutInFoundation(card: Card, foundationIndex: number) {
        console.log('----found1', card.id, foundationIndex);
        if (card.owner == CardOwner.foundation || card.owner == CardOwner.stock) {
            return false;
        }

        if (foundationIndex == 1 && card.suit != Suit.Heart) {
            return false;
        }

        if (foundationIndex == 2 && card.suit != Suit.Spade) {
            return false;
        }

        if (foundationIndex == 3 && card.suit != Suit.Diamond) {
            return false;
        }

        if (foundationIndex == 4 && card.suit != Suit.Club) {
            return false;
        }

        // if card owned by tableau it should be at the top
        if (card.owner == CardOwner.tableau) {
            const top = cardsQuery.getTopByOwnerAndIndex(card.owner, card.ownerIndex);
            if (!top || card.id != top.id) {
                return false;
            }
        }

        const ownerIndex = coreUtil.getFoundation4IndexForSuit(card.suit);
        const top = cardsQuery.getTopByOwnerAndIndex(CardOwner.foundation, ownerIndex);
        if (!top) {
            return card.rank == 1;
        }
        return top.rank + 1 == card.rank;
    }

    canMoveToSpecificTableauEmptyFrame(card: Card, tableauIndex: number) {
        // check if tableau is empty
        const top = cardsQuery.getTopByOwnerAndIndex(CardOwner.tableau, tableauIndex);
        if (top) {
            return false;
        }

        if (!this.canMoveCardSequence(card, tableauIndex)) {
            return false;
        }

        return true;
    }

    canMoveCardSequence(card: Card, destTableauIndex?: number | undefined) {
        // get top card
        const top = cardsQuery.getTopByOwnerAndIndex(card.owner, card.ownerIndex);
        if (!top) {
            // that should never happen
            return true;
        }

        // get sequence size
        const size = top.order - card.order + 1;

        const maxSize = this.calcMaxMoveableSequenceSize(destTableauIndex);

        return size <= maxSize;
    }

    calcMaxMoveableSequenceSize(destTableauIndex?: number | undefined) {
        // count empty tableau
        const topCards = cardsQuery.getTopTableauCards();
        const emptyTabCount = [1, 2, 3, 4, 5, 6, 7, 8].filter(
            (t) => !topCards.find((c) => c.ownerIndex == t) && t != destTableauIndex
        ).length;

        // count empty free cell
        const freeCount = 4 - cardsQuery.getAllByOwner(CardOwner.freeCell).length;

        // calc the max valid size to move
        // https://boardgames.stackexchange.com/questions/45155/freecell-how-many-cards-can-be-moved-at-once
        const maxSize = Math.pow(2, emptyTabCount) * (freeCount + 1);
        return maxSize;
    }
}

export const judge = new Judge();
