
import { defineComponent, ref } from 'vue';
import { gameQuery } from '@/state/game/game.query';
import { display } from '@/games/freecell/display';
import { subscribeTo, useObservable } from '@/core/rxjs-helpers';
import SuitFrame from '@/components/SuitFrame.vue';
import EmptyFrame from '@/components/EmptyFrame.vue';
import { recomputable } from '@/composable/recomputable';

export default defineComponent({
    components: {
        SuitFrame,
        EmptyFrame,
    },

    setup() {
        const foundationHighlight = useObservable(gameQuery.foundationIndexHighlight$);
        const tableauHighlight = useObservable(gameQuery.tableauIndexHighlight$);
        const recompute = ref(0);

        subscribeTo(gameQuery.gameSize$, () => {
            setTimeout(() => {
                recompute.value += 1;
            });
        });

        const getFrameStyle = (x: number, y: number) => {
            return {
                top: `${y}px`,
                left: `${x}px`,
                width: `${display.cardSize.width}px`,
                height: `${display.cardSize.height}px`,
                borderRadius: `${display.cardSize.width * 0.06}px`,
            };
        };

        const tableau1Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau1Position.x, display.tableau1Position.y);
        });

        const tableau2Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau2Position.x, display.tableau2Position.y);
        });

        const tableau3Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau3Position.x, display.tableau3Position.y);
        });

        const tableau4Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau4Position.x, display.tableau4Position.y);
        });

        const tableau5Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau5Position.x, display.tableau5Position.y);
        });

        const tableau6Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau6Position.x, display.tableau6Position.y);
        });

        const tableau7Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau7Position.x, display.tableau7Position.y);
        });

        const tableau8Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau8Position.x, display.tableau8Position.y);
        });

        const free1Style = recomputable(recompute, () => {
            return getFrameStyle(display.free1Position.x, display.free1Position.y);
        });

        const free2Style = recomputable(recompute, () => {
            return getFrameStyle(display.free2Position.x, display.free2Position.y);
        });

        const free3Style = recomputable(recompute, () => {
            return getFrameStyle(display.free3Position.x, display.free3Position.y);
        });

        const free4Style = recomputable(recompute, () => {
            return getFrameStyle(display.free4Position.x, display.free4Position.y);
        });

        const getFoundation = () => {
            return {
                lineHeight: `${display.cardSize.height}px`,
                fontSize: `${display.cardSize.height * 0.3}px`,
            };
        };

        const foundation1Style = recomputable(recompute, () => {
            const frame = getFrameStyle(
                display.foundation1Position.x,
                display.foundation1Position.y
            );
            return {
                ...frame,
                ...getFoundation(),
            };
        });

        const foundation2Style = recomputable(recompute, () => {
            const frame = getFrameStyle(
                display.foundation2Position.x,
                display.foundation2Position.y
            );
            return {
                ...frame,
                ...getFoundation(),
            };
        });

        const foundation3Style = recomputable(recompute, () => {
            const frame = getFrameStyle(
                display.foundation3Position.x,
                display.foundation3Position.y
            );
            return {
                ...frame,
                ...getFoundation(),
            };
        });

        const foundation4Style = recomputable(recompute, () => {
            const frame = getFrameStyle(
                display.foundation4Position.x,
                display.foundation4Position.y
            );
            return {
                ...frame,
                ...getFoundation(),
            };
        });

        return {
            tableau1Style,
            tableau2Style,
            tableau3Style,
            tableau4Style,
            tableau5Style,
            tableau6Style,
            tableau7Style,
            tableau8Style,
            free1Style,
            free2Style,
            free3Style,
            free4Style,
            foundation1Style,
            foundation2Style,
            foundation3Style,
            foundation4Style,
            foundationHighlight,
            tableauHighlight,
        };
    },
});
